<template>
  <Details
    ref="details"
    :title="isAdmin ? 'Customer Admin' : 'Customer User'"
    resource="customerProfiles"
    :base-path="basePath"
    :request-params="{customerId: customerId}"
    routeIdParam="userId"
    :title-template="isAdmin ? 'edit admin {fullName}' : 'edit user {fullName}'"
  >
    <template v-slot:default="detailsProps">
      <UserForm
        :initialValues="getInitialValues(detailsProps.record)"
        :admin="isAdmin"
        :on-submit="detailsProps.handleSubmit"
        :loading="detailsProps.loading"
        @close="detailsProps.redirectToList"
        :disable-email="true"
        :stepStartWith="stepStartWith"
      />
    </template>
  </Details>
</template>

<script>
  import {isEmpty, uniqBy} from "lodash-es";
  import ModalNavigation from '@/mixins/ModalNavigation';
  import Details from '@/components/auth/details/Details';
  import UserForm from '@/views/auth/customers/users/UserForm';
  import {AUTH_ALIAS, ROLES} from '@/components/auth/constants';

  export default {
    name: 'UsersEdit',
    mixins: [ModalNavigation],
    components: {
      UserForm,
      Details
    },
    data() {
      return {
        stepStartWith: 0,
      }
    },
    computed: {
      customerId: function () {
        return this.$route.params.customerId;
      },
      userId: function () {
        return this.$route.params.userId;
      },
      basePath: function () {
        return this.$route.name.replace('edit', 'details');
      },
      isAdmin: function () {
        return this.$route.path.includes('admins');
      },
    },
    methods: {
      getInitialValues(record) {
        if (!record || isEmpty(record)) {
          return {};
        }

        const {appRoles = [], communities = [], metaRoles = [], role, ...values} = record;

        // Check that appRoles are global for all the customer communities. At once appRoles can be either global or not
        const isGlobal = appRoles[0]?.roles.find(r => r.global) || metaRoles[0]?.roles.find(r => r.isGlobal);

        const selectedCommunities = isGlobal
            ? [{key: 'all', value: 'all'}]
            : uniqBy(communities.map(({communityId: key, communityName: value}) => ({key, value})), 'key');

        const authAppRole = role === ROLES.USER ? ROLES.NO_ROLE : role;

        const selectedAppRoles = {
          [AUTH_ALIAS]: authAppRole,
          ...appRoles.reduce((acc, item) => ({...acc, [item.appId]: item.roles[0].appRoleId}), {}),
          ...metaRoles.reduce((acc, item) => ({...acc, [item.bundleId]: item.metaRoleId}), {}),
        };

        return {
          ...values,
          appRoles: selectedAppRoles,
          communities: selectedCommunities,
        };
      }
    },

    created() {
      const queryStep = Number(this.$route.query?.wizard);
      this.stepStartWith = isNaN(queryStep) ? 0 : queryStep;
    },
  };
</script>
